// Scripts for the slider banner component
import '../../../../styles/src/components/banners/slider.scss';

import { Swiper, Autoplay, Controller, A11y, Navigation } from 'swiper/swiper.esm.js';
import Player from '@vimeo/player';

Swiper.use([Autoplay, Controller, Navigation, A11y]);

// Main slider
let bannerSlider = new Swiper(
  '.banner-slider__slider--slider',
  {
    autoplay: {
      delay: 5000
    },
    loop: true,
    navigation: {
      nextEl: '.banner-slider__next-slide',
      prevEl: '.banner-slider__prev-slide'
    },
    preloadImages: false
  }
);


/**
 * Set up each video slide item
 */
const sliders = document.getElementsByClassName('banner-slider');
if( typeof sliders !== 'undefined' || sliders !== null ) {
  for( let slider of sliders ) {

    // Identify the dialog box for the component
    const dialog = slider.querySelector('.banner-slider__dialog');

    // Identify the video iframe
    const videoIframe = slider.querySelector('.banner-slider__iframe');

    // Identify the slides
    const slides = slider.getElementsByClassName('banner-slider__slide');
    for( let slide of slides ) {

      // Identify vimeo popups
      let bannerLink = slide.querySelector('.banner-slider__link');
      if( typeof bannerLink !== 'undefined' && bannerLink !== null ) {

        // Only set up the popup if the link is a vimeo popup
        if( bannerLink.dataset.type === 'popup-vimeo' ) {

          // When the button is clicked, pass the video ID to the dialog box
          bannerLink.addEventListener('click', function() {
            // Identify the video ID to pass to the dialog box
            const videoId = bannerLink.dataset.href;
            videoIframe.src = 'https://player.vimeo.com/video/' + videoId;
            const player = new Player(videoIframe);

            // Open the dialog
            dialog.showModal();

            /**
             * When the dialog is closed or the escape button is pressed, pause
             * the video
             */
            let dialogClose = dialog.querySelector('.banner-slider__close');
            dialogClose.addEventListener('click', function() {
              closeDialog();
            });
            dialog.addEventListener('close', function() {
              closeDialog();
            });

            function closeDialog() {
              // Stop the video and destroy the player
              if( player !== null )
                player.pause().catch(function(error) {
                  switch (error.name) {
                    case 'PasswordError':
                      // the video is password-protected and the viewer needs to enter the
                      // password first
                      break;

                    case 'PrivacyError':
                      // the video is private
                      break;

                    default:
                      // some other error occurred
                      break;
                  }
                });

              // Close the dialog
              dialog.close();
            }
          });

        }

      }
    }
  }
}


// Button management for slides

// if link-type is "inner-page" or "outside-link", the button should act as a
// normal anchor link
let links = document.querySelectorAll("[data-type='inner-page'], [data-type='outside-link']");
Array.prototype.forEach.call( links, function( button ) {
  // Grab the url attached to the element
  let url = button.dataset.href;
  button.onclick = function() {
    location.href = url;
  }
});
